import React from "react";
import "./navbar.css";
import { RiMenu3Fill } from "react-icons/ri";
function Navbar() {
    return (
        <nav className="navbar">
            <div className="center col-2"><a href="">
                <img src="/ft.png" alt="Icon" className="navbar-icon" />
            </a></div>
            <div className="navbar-container col-8">
                <ul className="navbar-menu">
                    <li><a href="#home">HOME</a></li>
                    <li><a href="https://www.felixacare.com/">FELIXACARE</a></li>
                    <li><a href="https://www.felixacare.com/contact/">CONTACT US</a></li>
                    <li><a href="https://www.felixacare.com/about-us/">ABOUT US</a></li>
                </ul>
            </div>
            <div className="center col-2">
                {/* <RiMenu3Fill /> */}
            </div>
        </nav>
    );
}

export default Navbar;
