import React from "react";
import Navbar from "./Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/main.css';
import { FaRegHospital } from "react-icons/fa";
import { FaClinicMedical } from "react-icons/fa";
import { FaStethoscope } from "react-icons/fa";
import { FaTooth } from "react-icons/fa";
import { FaLeaf } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { FaHospital } from "react-icons/fa";
import { FaHospitalAlt } from "react-icons/fa";
import { FaPersonBooth } from "react-icons/fa";
import { FaPersonWalking } from "react-icons/fa6";
import { FaHandHoldingMedical } from "react-icons/fa6";
import { FaHandHoldingHeart } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TbMailFilled } from "react-icons/tb";
import { FaPhone } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
function App() {
  const items = ["Simplified Prescription", "Advanced Doctors Note", "Easy patient onboarding", "State of the art of lab module", "radiology ordering", "Pharmacy ordering", "Digitized payment management", "analytics dashboard", "Front desk for administration"];

  const handleButtonClick = () => {
    window.location.href = "https://www.felixacare.com/demo/";
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      <Navbar />
      <div className="nav-background">
        <div className="image-background">
          <div className="image-child1">
            <label>FelixaTouch</label>
            <div className="image-child2">
              <label>The Simple Doctor App That Meets All Your Clinical Needs</label>
              <br />
              <p>Innovative clinic software features simplified e-prescriptions, instant access to patient records via an advanced EMR system, and efficient tools for patient onboarding, appointment scheduling, and queue management.</p>
            </div>
            <button onClick={handleButtonClick}>Schedule Meeting</button>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-child col-lg-4 col-md-4 col-12">
          <div><FaRegHospital /><label>Hospital (IP & OP)</label></div>
          <div><FaClinicMedical /><label>General Clinic</label></div>
          <div><FaStethoscope /><label>Doctor Practice</label></div>
          <div><FaTooth /><label>Dental Clinic</label></div>
          <div><FaLeaf /><label>Naturopathic hospital</label></div>
          <div><FaHome /><label>Home Care</label></div>
        </div>
        <div className="content-child1 col-lg-4 col-md-4 col-12">
          <label className="head">FelixaTouch</label>
          <label className="p">Access FelixaTouch to manage clinic  using any smart devices tablets, desktops, or mobile phones to provide seamless access from anywhere</label>
          <img src="/image.png" alt="Icon" className="image-icon" />
        </div>
        <div className="content-child col-lg-4 col-md-4 col-12">
          <div><FaHospital /><label>Wellness Center</label></div>
          <div><FaHospitalAlt /><label>Old Age Care Home</label></div>
          <div><FaPersonBooth /><label>General Therapy Center</label></div>
          <div><FaPersonWalking /><label>Physio Therapy Clinic</label></div>
          <div><FaHandHoldingMedical /><label>Palliative Center</label></div>
          <div><FaHandHoldingHeart /><label>Ayurvedic/Homeo Clinic</label></div>
        </div>
      </div>
      <div className="features">
        <div className="col-lg-7 col-md-7 col-12 feature-col">
          <label>Key Features</label>
          <img src="/features.png" alt="Icon" className="feautures-icon" />
        </div>
        <div className="col-lg-5 col-md-5 col-12 cent">
          <ul className="list-ele">
            {items.map((item, index) => (
              <li key={index} className="listItem">
                <FaStarHalfAlt className="icon" />
                <span className="text">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="why">
        <label style={{ margin: "auto" }}>Why Choose FelixaTouch</label>
        <div style={{ display: "flex" }}>
          <div className="col-lg-4 col-md-4 col-12">
            <img src="/two.jpg" alt="Icon" className="feautures-icon" />
          </div>
          <div className="col-lg-4 col-md-4 col-12 why-content">
            <span>Introducing</span>
            <label>Doctor's Tablet</label>
            <label>Doctor's Key</label>
          </div >
          <div className="col-lg-4 col-md-4 col-12">
            <img src="/service.png" alt="Icon" className="feautures-icon" />
          </div>
        </div>
        <div className="why">
          <FaCloudDownloadAlt />
          <label style={{ margin: "auto" }}>Fully Cloud Approach</label>
          <button onClick={handleButtonClick} >Schedule Demo</button>
        </div>
        <div className="icon-div">
          <div className="icon-div1">
            <div style={{ backgroundColor: "#4267B2" }}><FaFacebook /></div>
            <div style={{ backgroundColor: "#08a0e9" }}><FaTwitter /></div>
            <div style={{ backgroundColor: "#cf0202" }}><FaYoutube /></div>
            <div style={{ backgroundColor: "#000" }}><FaInstagram /></div>
          </div>
          <label>Connect With Us!</label>
          <div className="icon-div1"> <label>Powered By FelixaCare</label></div>
        </div>
      </div>
      <div className="contact">
        <div className="contact-det col-lg-6 col-md-6 col-12">
          <label>CONTACT</label>
          <span>A- 307 , United Crossandra</span>
          <span>Opposite Horamavu Lake, Hormavu Agara</span>
          <span>Bangalore, Karnataka , 560043</span>
          <span>India</span>
          <span>
            <TbMailFilled />&nbsp;Contact@IntPurple.com</span>
          <span>
            <FaPhone /> &nbsp;+91 9731066055 +91 7561057363</span>
        </div>
        <div className="contact-det col-lg-6 col-md-6 col-12" style={{ gap: "20px" }}>
          <label>A product from</label>
          <img src="/intprpl.png" className="feautures-icon" />
          <label style={{ display: "flex", gap: "10px" }}>
            <FaLinkedin />
            <FaFacebook />
            <FaTwitter />
          </label>
        </div>
      </div>
      <div className="privacy">
        <a
          href="/privacy.html"
          style={{ color: "#fff", marginLeft: "auto", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          PRIVACY POLICY
        </a>
      </div>
    </div >
  );
}

export default App;
